<template>
  <div class="container blop">
    <div class="row">
      <div class="halt">
        <h1>Impressum</h1>
      </div>
      <div class="row">
        <p>
          Peter Müller & Sohn OHG <br />
          Platterstraße 88 <br />
          65232 Taunusstein <br />
          <br />
          <span class="bold">Abteilung:</span> <br />
          Kfz Meister Werkstatt Müller (MWM)<br />
          Obere Waldstraße 9 <br />
          65232 Taunusstein
          <br />
          <br />
          Inhaltlich Verantwortlicher gem §10 Abs. 3 MDStV: <br />
          Hans-Peter Müller
        </p>
        <p>Handelsregister: HRA 783</p>
        <p>Registergericht: Amtsgericht Wiesbaden</p>
        <p>
          <span class="bold">Geschäftsführer:</span> <br />
          Hans-Peter Müller <br />
        </p>
        <h4>Kontakt</h4>
        <p>
          Telefon: +49 (0) 6128 84322 <br />
          E-Mail: info@kfz-mwm.de
        </p>
        <h4>Umsatzsteuer-ID</h4>
        <p>Umsatzsteuer-ID: DE 113900879</p>
        <br /><br /><br /><br />
        <h4>Inhalt des Onlineangebotes</h4>
        <p class="tleft">
          Der Autor übernimmt keinerlei Gewähr für die Aktualität, Korrektheit,
          Vollständigkeit oder Qualität der bereitgestellten Informationen.
          Haftungsansprüche gegen den Autor, welche sich auf Schäden materieller
          oder ideeller Art beziehen, die durch die Nutzung oder Nichtnutzung
          der dargebotenen Informationen bzw. durch die Nutzung fehlerhafter und
          unvollständiger Informationen verursacht wurden, sind grundsätzlich
          ausgeschlossen, sofern seitens des Autors kein nachweislich
          vorsätzliches oder grob fahrlässiges Verschulden vorliegt.
          <br />
          <br />
          Alle Angebote sind freibleibend und unverbindlich. Der Autor behält es
          sich ausdrücklich vor, Teile der Seiten oder das gesamte Angebot ohne
          gesonderte Ankündigung zu verändern, zu ergänzen, zu löschen oder die
          Veröffentlichung zeitweise oder endgültig einzustellen.
        </p>
        <h4>Verweise und Links</h4>
        <p class="tleft">
          Bei direkten oder indirekten Verweisen auf fremde Webseiten
          ("Hyperlinks"), die außerhalb des Verantwortungsbereiches des Autors
          liegen, würde eine Haftungsverpflichtung ausschließlich in dem Fall in
          Kraft treten, in dem der Autor von den Inhalten Kenntnis hat und es
          ihm technisch möglich und zumutbar wäre, die Nutzung im Falle
          rechtswidriger Inhalte zu verhindern.
          <br />
          <br />
          Der Autor erklärt hiermit ausdrücklich, dass zum Zeitpunkt der
          Linksetzung keine illegalen Inhalte auf den zu verlinkenden Seiten
          erkennbar waren. Auf die aktuelle und zukünftige Gestaltung, die
          Inhalte oder die Urheberschaft der verlinkten/verknüpften Seiten hat
          der Autor keinerlei Einfluss. Deshalb distanziert er sich hiermit
          ausdrücklich von allen Inhalten aller verlinkten /verknüpften Seiten,
          die nach der Linksetzung verändert wurden. Diese Feststellung gilt für
          alle innerhalb des eigenen Internetangebotes gesetzten Links und
          Verweise sowie für Fremdeinträge in vom Autor eingerichteten
          Gästebüchern, Diskussionsforen, Linkverzeichnissen, Mailinglisten und
          in allen anderen Formen von Datenbanken, auf deren Inhalt externe
          Schreibzugriffe möglich sind. Für illegale, fehlerhafte oder
          unvollständige Inhalte und insbesondere für Schäden, die aus der
          Nutzung oder Nichtnutzung solcherart dargebotener Informationen
          entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen
          wurde, nicht derjenige, der über Links auf die jeweilige
          Veröffentlichung lediglich verweist.
        </p>
      </div>
    </div>
  </div>
  <footer>
      <router-link to="/privacy">    
        <div class="container privacy">Datenschutz</div>
      </router-link>
    </footer>
</template>

<style>
h4 {
  text-align: center;
}
.bold {
  font-weight: 750;
}
</style>

<script>
export default {
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>